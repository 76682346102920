import { DefaultButton, Dialog, MessageBar, MessageBarType, PrimaryButton, ProgressIndicator, Stack } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiPrefix } from "../lib/helper";
import { useAuthenticationContext } from "../providers/authprovider";
import { makeApiCall } from "../services/apiService";

interface ILegalTerm {
    ReusableHtml: string;
    Title: string;
}

const Consent: React.FunctionComponent = () => {

    const [legalTerms, setLegalTerms] = useState<ILegalTerm>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();
    const [saving, setSaving] = useState(false);
    const [decliendMessage, setDeclinedMessage] = useState<string>();
    const navigate = useNavigate();

    const authContext = useAuthenticationContext();
    const DEFAULT_LEGAL_TERM = "AA/EEO";

    const queryParams = new URLSearchParams(window.location.search);
    const legalTerm = queryParams.get("legalTerm") || DEFAULT_LEGAL_TERM;
    const redirect = queryParams.get("redirect") || "/";
    const productName = queryParams.get("productName");

    useEffect(() => {
        setError(undefined)
        if (authContext.Result?.IsAuthenticated) {
            setLoading(true);
            makeApiCall(`${apiPrefix}getLegalTermsForConsent?legalTerm=${legalTerm}`).then(term => {
                setLegalTerms(term);
            }).catch(err => {
                console.error(err);
                setError("Error getting consent terms");
            }).finally(() => {
                setLoading(false);
            })
        }
        else if (!authContext.Pending) {
            setError("You must be logged in to view this page")
            setLoading(false);
        }
    }, [authContext.Result, legalTerm, authContext.Pending]);

    const _updateUserConsent = (status: "Accept" | "Deny") => {
        if (status === "Deny") {
            setDeclinedMessage("You must accept the terms of the agreement to continue")
        }
        else {
            setSaving(true);
            let qs = `?legalTerm=${encodeURIComponent(legalTerm)}&consent=${status === "Accept"}&redirect=${encodeURIComponent(redirect)}`;
            if (productName) {
                qs += `&productName=${productName}`;
            }
            makeApiCall(`${apiPrefix}updateUserConsent${qs}`, "POST", JSON.stringify({ text: legalTerms!.ReusableHtml })).then(() => {
                if (status === "Accept") {
                    window.location.assign(redirect);
                }
                else {
                    setLegalTerms(undefined);
                    setError("By denying the legal terms, you will unable to access information on this site");
                }
            }).catch(err => {
                console.error(err);
                setError("Error setting user consent terms");
            }).finally(() => {
                setSaving(false);
            })
        }
    }

    return <>
        {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
        {loading && <ProgressIndicator />}
        {legalTerms?.ReusableHtml && !decliendMessage && <Dialog
            modalProps={{ layerProps: { eventBubblingEnabled: false } }}
            styles={{
                main: {
                    selectors: {
                        ['@media (min-width: 480px)']: {
                            width: "calc(100vw - 80px)",
                            minWidth: "calc(100vw - 80px)",
                            maxWidth: "calc(100vw - 80px)"
                        },
                        ['@media (min-width: 1280px)']: {
                            width: 1200,
                            minWidth: 1200,
                            maxWidth: 1200
                        }
                    }
                }
            }}
            hidden={false}
            dialogContentProps={{ title: legalTerms.Title, titleProps: { style: { textAlign: "center" } } }}             
            >
            <Stack tokens={{ childrenGap: 10 }}>
                <div style={{maxHeight: "calc(100vh - 250px)"}} dangerouslySetInnerHTML={{ __html: legalTerms.ReusableHtml }} className="legalTermsBox" />
                <Stack horizontal tokens={{ childrenGap: 10 }} className="legalTermsButtons">
                    <PrimaryButton text="Accept" disabled={saving} onClick={() => _updateUserConsent("Accept")} />
                    <PrimaryButton text="Deny" disabled={saving} onClick={() => _updateUserConsent("Deny")} />
                </Stack>
            </Stack>
        </Dialog>}
        {decliendMessage && <Stack tokens={{childrenGap: 10}}>
            <MessageBar messageBarType={MessageBarType.warning}>{decliendMessage}</MessageBar>
            <Stack horizontal tokens={{childrenGap: 10}}>
                <PrimaryButton text="View Terms" onClick={() => window.location.assign(window.location.href)} />
                <DefaultButton text="Go home" onClick={() => navigate("/")} />
            </Stack>
        </Stack>}
    </>
}

export default Consent;