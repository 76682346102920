import { IStackTokens,  Link, MessageBar, MessageBarType, Pivot, PivotItem, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../elements/pageWrapper';
import { apiPrefix } from '../lib/helper';
import { useAuthenticationContext } from '../providers/authprovider';
import { useLoadContext } from '../providers/loadProvider';
import { makeApiCall } from '../services/apiService';

export interface ILinkItem {
    Title: string;
    URL: string;
    Category: string;
}

const FreqLinks: React.FunctionComponent = () => {
    // Tokens definition for stack
    const GapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10
    };

    const [linkItems, setLinkItems] = useState<ILinkItem[]>();
    const [error, setError] = useState<string>();
    const authContext = useAuthenticationContext();
    const loadContext = useLoadContext();

    if (authContext.Result?.IsAuthenticated) {

    }

    useEffect(() => {
        setError(undefined);
        loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
        if (authContext.Result?.IsAuthenticated) {
            setError(undefined);
            loadContext.SetLoadingMessage({ message: `Loading frequently used links...` });
            makeApiCall(`${apiPrefix}getFreqLinks`).then(linkData => {
                setLinkItems(linkData);
            }).catch(err => {
                console.error(err);
                const message = err === 'Unauthorized' ? 'You are not authorized to view this data' : 'Error: See console for more information';
                setError(message);
            }).finally(() => {
                loadContext.SetLoadingMessage(undefined);
            });

        }
        else if (!authContext.Pending) {
            setError('You must be logged in to view this page')
            loadContext.SetLoadingMessage(undefined);
        }
    }, [authContext?.Result, authContext?.Result?.IsAuthenticated, authContext.Pending]);

    return (
        <PageWrapper title='Frequently Used Links'>
            {!loadContext.LoadingMessage && <>
                {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
                {!linkItems && !error ? <MessageBar messageBarType={MessageBarType.info}>There doesn't seem to be any content available to you yet...</MessageBar> : <>
                <Pivot aria-label="Basic Pivot Example">
                    <PivotItem
                        headerText="Meteorcomm Links"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'My Files Title',
                        }}
                    >
                        <Stack className='freqLinksBody'>{linkItems.filter((obj) => obj.Category === 'Meteorcomm Links').map((item, idx) => <p><Link target='_blank' title={item?.Title} href={item?.URL} key={item?.URL}>{item?.Title}</Link></p>)}</Stack>
                    </PivotItem>
                    <PivotItem headerText="External Links" headerButtonProps={{'data-title':'External Links'}}>
                        <Stack className='freqLinksBody'>{linkItems.filter((obj) => obj.Category === 'External Links').map((item, idx) => <p><Link target='_blank' title={item?.Title} href={item?.URL} key={item?.URL}>{item?.Title}</Link></p>)}</Stack>
                    </PivotItem>
                </Pivot></>}
            </>}
        </PageWrapper>
    );
}

export default FreqLinks