import { MessageBar, MessageBarType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageWrapper } from '../elements/pageWrapper';
import { DetailsListProduct } from '../elements/prodlist';
import { apiPrefix } from '../lib/helper';
import { useAuthenticationContext } from '../providers/authprovider';
import { useLoadContext } from '../providers/loadProvider';
import { makeApiCall } from '../services/apiService';

export interface IFile {
    Name: string;
    ServerRelativeUrl: string;
    Length: string;
    FolderName: string
}

export interface IProductPackage {
    FileLeafRef: string;
    Product: {
        StatusCalculated: string;
        _Revision: string;
        Title: string;
    },
    Folder: {
        ServerRelativeUrl: string;
        Name: string;
        Files: IFile[];
    },
    ReleaseDate: string;
}

export interface IProductLine {
    Title: string;
    RelatedProductLines?: IProductLine[];
    LegalTerm: {
        Title: string;
    }
}

const Products: React.FunctionComponent = () => {
    const [productPackages, setProductPackages] = useState<IProductPackage[]>();
    const [productLine, setProductLine] = useState();
    const [error, setError] = useState<string>();
    const authContext = useAuthenticationContext();
    const loadContext = useLoadContext();
    const params = useParams();
    const param = params?.pid;
    const releaseId = params?.releaseid;
    const paramID = typeof param === 'string' ? param : 'Product ID not specified'; /* Need to figure out how to deal with null value */

    const compareSemanticVersions = (a: IProductPackage, b: IProductPackage) => {
        const a1 = a.Product._Revision.split(' ')[0].split('.');
        const b1 = b.Product._Revision.split(' ')[0].split('.');
        const len = Math.min(a1.length, b1.length);
        for (let i = 0; i < len; i++) {
            const a2 = +a1[i] || 0;
            const b2 = +b1[i] || 0;

            if (a2 !== b2) {
                return a2 < b2 ? 1 : -1;
            }
        }

        return a1.length < b1.length ? 1 : -1;
    };

    useEffect(() => {
        setError(undefined);
        loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
        if (authContext.Result?.IsAuthenticated) {
            setError(undefined);
            const getProdLine = makeApiCall(`${apiPrefix}getProductLine?productLine=${encodeURIComponent(param)}&redirect=${encodeURIComponent(`/product/${param}`)}`).then((pLine) => {
                setProductLine(pLine);
            });
            const getProds = makeApiCall(`${apiPrefix}getProducts?productLine=${encodeURIComponent(param)}`).then((products) => {
                loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
                const prodsToQuery = products.map((pdct: { Title: string; }) => pdct.Title);
                const allProdQueryPromises = prodsToQuery.map((prod: string) => {
                    return makeApiCall(`${apiPrefix}getProductPackages?productName=${prod}&redirect=${encodeURIComponent(`/product/${param}`)}`);
                });
                Promise.all(allProdQueryPromises).then(allPackageArray => {
                    const allPackages = allPackageArray.reduce((p, n) => {
                        return p.concat(n);
                    });
                    setProductPackages(allPackages.sort(compareSemanticVersions));
                });
            }).catch(err => {
                console.error(err);
                const message = err === 'Unauthorized' ? 'You are not authorized' : 'Error: See console for more information';
                setError(message);
            });

            Promise.all([getProdLine, getProds]).then(() => {
                loadContext.SetLoadingMessage(undefined);
            });
        }
        else if (!authContext.Pending) {
            setError('You must be logged in to view this page')
            loadContext.SetLoadingMessage(undefined);
        }
    }, [authContext?.Result, authContext?.Result?.IsAuthenticated, paramID, param, authContext.Pending]);

    return <PageWrapper title={paramID} imagePath='/img/products.png'>
        <>
            {error && !loadContext.LoadingMessage?.message && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            {productPackages?.length > 0 && !error && !loadContext.LoadingMessage?.message &&
                <DetailsListProduct productPackages={productPackages} productLine={paramID} releaseId={releaseId} productLineData={productLine} />
            }
            {!error && !loadContext.LoadingMessage?.message && !productPackages && <MessageBar messageBarType={MessageBarType.warning}>There doesn't seem to be any content available this product</MessageBar>}

        </>
    </PageWrapper>
}

export default Products