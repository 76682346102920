import { Dialog, PrimaryButton, Stack } from "@fluentui/react";
import { Link, useNavigate } from "react-router-dom";

interface ILegalTerm {
    ReusableHtml: string;
    Title: string;
}

const TermsOfUse: React.FunctionComponent = () => {


    const legalHTML = `
    ​<div class="pageContent text-justify">
    <p>
        <strong>PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS SITE</strong>.<br /><br />Your use of this extranet
        site is subject to non-disclosure (&quot;NDA&#39;s&quot;) Master Purchase Agreement (&quot;MPA&quot;) or Master
        Services Agreements (&quot;MSA&#39;s&quot;) your company has negotiated with Meteorcomm. &#160;If the terms and
        conditions contained herein conflict with the MSA, MPA or NDA in place with Meteorcomm, the MSA, MPA or NDA
        shall control. &#160;Your use of this extranet constitutes your acceptance to be bound by to the provisions in
        this Terms of Use Agreement (the &quot;Agreement&quot;) without limitation, qualification or change. Meteorcomm
        may update or modify this Agreement from time to time without prior individual notice by posting a revised
        Agreement on the extranet, so please check this page again for changes from time to time. Your continued use of
        the extranet will constitute your acceptance of any such changes.
        extranetextranetextranet<br /><br /><strong>Permitted Use</strong><br /> This extranet is made available to
        provide you with information about Meteorcomm® and its products and services and method for contacting
        Meteorcomm® for additional information. Any other use of the extranet shall exceed the &quot;authorized
        access&quot; you have to the extranet as that term is defined under 18 U.S.C. §
        1030.<br /><br /><strong>Intellectual Property Notice</strong>
    </p>
    <p>The extranet and all images, audio, video and related informational materials in any medium available on the
        extranet are the property of Meteorcomm® and are protected by US and international copyright laws, trade dress,
        trademark, moral rights, and other intellectual property rights. Except as specifically agreed by Meteorcomm®,
        no portion or element of the extranet may be copied or retransmitted via any means and all related rights shall
        remain the exclusive property of Meteorcomm®.
        <br />
        <br />
    </p>
    <p>All trademarks appearing on this extranet are the property of their respective owners. Nothing contained on this
        extranet should be construed as granting, by implication, estoppel, or otherwise, any license or right to use
        any trademark displayed on the extranet without the written permission of the party that may own the applicable
        trademark. </p>
    <p>
        <br />Third Party Content is subject to separate copyright and other intellectual property notices stated in the
        Third Party Terms below.
    </p>
    <p>
        <strong>Third Party Terms</strong><br /> Our extranet may link to other third-party extranets. This linking does
        not constitute our endorsement or approval of the content of those extranets or of the policies, products,
        services, or opinions expressed therein. These links are provided as a convenience and for informational
        purposes only. Meteorcomm® bears no responsibility for the accuracy, legality or content of an external site or
        for that of subsequent links. Contact the external site for answers to questions regarding its content. Subject
        to any specific Third Party Terms provided together or in conjunction with Third Party Content, the following
        provisions shall apply:
    </p>
    <p>1. Third Party Content. You acknowledge that the Third Party Content displayed or available through the Site
        contains information and other material that is protected by copyright, trademark or other proprietary rights of
        third party content providers. You agree to comply with any additional copyright notices, information, or
        restrictions contained in any Third Party Content. You may not publish, transmit, transfer or sell, reproduce,
        distribute, perform, display, or in any way exploit any of the Third Party Content, in whole or in part. You may
        not sublicense any Third Party Content to any third party or use the Third Party Content in any other media or
        in any location other than at the Site. You may not alter the Third Party Content in any way or create any
        derivative works from the Third Party Content. </p>
    <p>2. Termination of Access to Third Party Content. METEORCOMM®&#39;s licensors and suppliers reserve the right, at
        their discretion, to restrict, suspend or terminate your access to all or any part of Third Party Content at any
        time for any reason without prior notice or liability. METEORCOMM®&#39;s licensors and suppliers may change,
        suspend or discontinue all or any aspect of Third Party Content, including the availability, without prior
        notice or liability. </p>
    <p>3. Provisions Not Exclusive. The provisions set out under the heading Third Party Terms are not exclusive. They
        are supplemented by and must be read together with applicable provisions under the heading Disclaimers,
        Limitation, Exclusions below. </p>
    <p>
        <br />Without the express written permission of Meteorcomm®, you are prohibited from framing the Meteorcomm®
        extranet or linking to any page other than Meteorcomm®&#39;s homepage.
        <br />
        <br />
        <strong>Privacy </strong>
        <strong>Policy</strong><br /> Please refer to our
        <a href="https://meteorcomm.com/wp-content/uploads/Meteorcomm-Privacy-Policy.pdf" target="_blank">Privacy Policy</a> which is hereby incorporated by
        reference.
        <br />
        <br />
        <strong>Posting Comments and Feedback</strong><br /> Our extranet contains forms for your use in requesting
        product support and information about our products and services as well as employment opportunities at
        Meteorcomm®. These forms require you to provide us with certain contact information and permit you to attach and
        submit files that are 5mb or less in size. By submitting any of these forms you authorize Meteorcomm® to use the
        information you provide to contact you by email, phone, or other method and for any other purpose outlined in
        our
        <a href="https://meteorcomm.com/wp-content/uploads/Meteorcomm-Privacy-Policy.pdf" target="_blank">Privacy Policy</a>.
        <br />
        <br />You also represent that the information you submit or material you append to a form (&quot;Content&quot;)
        is true and accurate and shall not: (a) infringe the intellectual property, proprietary, privacy or publicity
        rights of any third party; (b) violate any law or regulation; (c) contain any defamatory, indecent, obscene,
        abusive, vulgar, hateful, harassing material or any child pornographic or other any material harmful to minors;
        (d) contain any viruses, trojan horses, disabling code, worms, time bombs, &quot;clear GIFs&quot; cancelbots, or
        other computer programming or routines that are intended to, or which in fact, damage, detrimentally interfere
        with, monitor, intercept, or expropriate any data, information, packets, or personally-identifiable information,
        or (e) constitute spam, flooding, advertisements, chain letters, pyramid schemes, or solicitations. You
        acknowledge and agree that Meteorcomm® may take any action it deems appropriate as to any Content communicated
        by you, if we believe that the Content or your activities on the extranet may expose Meteorcomm® to liability,
        or damage our relationship with any of our customers, suppliers, licensors, ISPs or other users of our extranet.
        As to all Content, you hereby grant to Meteorcomm® an exclusive, perpetual, worldwide, irrevocable, assignable,
        royalty-free license, fully sublicensable through multiple tiers, right to exercise all intellectual property
        rights, in and to all or any part of the Content, in any medium now known or hereafter developed, subject to
        restrictions and limitations imposed elsewhere on the extranet, and by applicable federal law.
        <br />
        <br />You agree to indemnify and hold harmless Meteorcomm®, its owners, affiliates, shareholders, directors,
        officers, employees, subsidiaries, joint ventures, agents, and third-party providers for claims or damages
        caused by your User Content, including reasonable attorneys&#39; fees and costs.<br /><br /><strong>Unauthorized
            Submissions </strong>
        <br />Meteorcomm® does not solicit or accept ideas or proposals from users regarding new products or services
        via the extranet and you are not authorized to make any such submissions. If you do submit an idea or proposal,
        you understand that it is likely that, given Meteorcomm®&#39;s experience, it is likely that Meteorcomm® is
        independently exploring, may have explored or may in the future explore programs and ideas generated that
        resemble or include your idea. In any case, by making a submission, you hereby forever unconditionally and
        irrevocably waive, disclaim, release and indemnify Meteorcomm® and each and all of its affiliates, directors,
        officers, shareholders, representatives and agents from any and all claims and causes of action that you may
        have at any time that Meteorcomm® misappropriated all or any part of your idea or proposal. You also agree: (a)
        your submission does not create any confidential or fiduciary relationship between you and Meteorcomm®; (b)
        Meteorcomm® may discuss and disclose your idea/proposal and any materials you submit to others; (c) Meteorcomm®
        is not obligated to return to you any materials that you submit or to protect any of those materials from damage
        or destruction; (d) Meteorcomm® is not obligated to pay any amount to you or otherwise compensate you or any
        other person in any way; (e) furnish you with any information of any kind or respond to your submission in any
        way; and (f) in the event you have any proprietary interest in any idea or materials you submit, you grant
        Meteorcomm® a license with respect thereto to the same extent and on the same terms as the Content you
        submit.<br /><br /><strong>Disclaimer of Warranties</strong><br /> The information on this extranet is provided
        &quot;as is&quot; and without warranties of any kind either express or implied. Disclaimer of Warranties.
        NEITHER METEORCOMM® NOR ITS LICENSORS OR SUPPLIERS WARRANT THAT THE SITE WILL BE UNINTERRUPTED OR ERROR FREE.
        METEORCOMM® AND ITS LICENSORS AND SUPPLIERS MAKE NO WARRANTY, EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, AND
        SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
        NONINFRINGEMENT WITH RESPECT TO (1) THE SITE, ANY THIRD PARTY SITE (COLLECTIVELY, &quot;THE SITES&quot;) AND (2)
        ANY CONTENT INCLUDING WITHOUT LIMITATION, THIRD PARTY CONTENT, METEORCOMM® DOCUMENTS AND OTHER CONTENT
        (COLLECTIVELY, &quot;ALL CONTENT&quot;). THE AVAILABILITY OF ALL CONTENT DEPENDS ON MANY FACTORS, INCLUDING
        METEORCOMM®&#39;s CONNECTION TO THE INTERNET, THE AVAILABILITY OF THE INTERNET AND THE INTERNET BACKBONE, AND
        EQUIPMENT THAT, BY ITS NATURE, IS NOT FAULT TOLERANT. METEORCOMM® AND ITS LICENSORS MAKE NO GUARANTEE OF ACCESS
        OR ACCURACY OF ANY CONTENT. YOU EXPRESSLY AGREE THAT THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE
        SITES AND THE ACCURACY OR COMPLETENESS OF ALL CONTENT IS ASSUMED SOLELY BY YOU.
    </p>
    <p>
        <br />
        <strong>Limitation of Liability</strong><br /> UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO,
        NEGLIGENCE, SHALL METEORCOMM® BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT
        FROM THE USE OF, OR THE INABILITY TO USE, THE MATERIALS ON THIS EXTRANET, EVEN IF METEORCOMM® OR A METEORCOMM®
        AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL METEORCOMM®
        HAVE ANY LIABILITY TO YOU FOR DAMAGES, LOSSES AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT
        NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE) FOR ACCESSING THIS SITE.<br /><br /><strong>Jurisdiction and
            Venue</strong><br /> You agree that any action at law or in equity (other than one in which preliminary or
        interim injunctive relief is sought) arising out of or relating to this Agreement shall be settled by
        arbitration to be held in King County, Washington, administered by the American Arbitration Association (the
        &quot;AAA&quot;) in accordance with its Commercial Arbitration Rules, except as provided herein, and judgment on
        the award rendered by the arbitrator may be entered in any court having jurisdiction over the matter.
        Nevertheless, nothing in this Agreement shall in any way limit your or our statutory rights and/or remedies, all
        of which are reserved and may be alleged in the arbitration process. Each party shall bear its own costs and
        expenses of arbitration. All forum fees and expenses, including the arbitrators&#39; fees, shall be shared
        equally by the parties, and shall not be assessed against either party as part of an award.<br /><br />In the
        event, and solely in such event, that arbitration of a dispute hereunder is prohibited by law, you agree that
        the action shall be filed, and that venue properly lies, only in courts located in King County, Washington, or
        the federal Washington District Court, and you hereby consent and submit to the personal jurisdiction of such
        courts.
        <br />
        <br />You hereby acknowledge, understand, and agree that, whether a dispute is resolved by arbitration or by a
        court, you waive the right to have the dispute heard or decided by a jury.<br /><br />You agree that any cause
        of action you may have arising out of or related to these terms of use must commence within one (1) year after
        the cause of action accrues; otherwise, such cause of action shall be permanently barred.
        <br />
        <br />
        <strong>General Provisions</strong><br /> These terms of use shall be governed by and construed in accordance
        with the laws of the State of Washington and the laws of the United States, without giving effect to any
        principles of conflicts of law. If any provision of these terms of use shall be unlawful, void or for any reason
        unenforceable, then that provision shall be deemed severable from these terms of use and shall not affect the
        validity and enforceability of any remaining provisions. No waiver of any provision of these terms of use by us
        shall be deemed a further or continuing waiver of such provision or any other provision, and our failure to
        assert any right or provision under these terms of use shall not constitute a waiver of such right or provision.
        In these terms of use, the word &quot;including&quot; is used illustratively, as if followed by the words
        &quot;but not limited to.&quot;
    </p>
    <p>
        <br />These terms of use were last updated on or about November 6, 2015.<br /><br />
    </p>
    <p>
        <strong>Meteorcomm®.com Privacy Policy</strong>
    </p>
    <p>This policy covers how Meteorcomm® treats Personally Identifiable Information (&quot;PII&quot;) that Meteorcomm®
        collects and receives, including information related to your past use of Meteorcomm® products and services. PII
        is information about you that can be traced directly back to and identifies an individual. PII includes your
        name, address, employer, email address, or phone number.<br /><br /><strong>Agreement</strong><br />By using
        this extranet, you signify your assent to this Privacy Policy. This Privacy Policy may change from time to time,
        so please check back periodically to ensure that you are aware of any changes. Your continued use of the
        extranet will signify your acceptance of these changes.<br /><br /><strong>Information Collection and
            Use</strong><br />Meteorcomm® collects personal information when you register with Meteorcomm® or through
        its extranet, or when you use Meteorcomm® products or services. In registering with Meteorcomm®, you must
        provide us with your name, address, company, title, e-mail address, phone number, and address and you may submit
        additional information and comments through the extranet. Whenever you visit Meteorcomm®&#39;s extranet,
        Meteorcomm® also receives and records information on our server logs from your browser, including your IP
        address, Meteorcomm® cookie information, and the pages you request, and relates it to the PII you provide.
        &#160;A cookie is an element of data that a web site can send to your browser, which may then store it on your
        system. Some pages within www.bnsf.com use cookies so we can better serve you when you return to our site. You
        can set your browser to notify you when you receive a cookie, giving you the chance to decide whether to accept
        it or not. &#160;Meteorcomm® uses this information for the following general purposes: to improve our products
        and services, to contact you, to conduct research, and to provide anonymous reporting for internal and external
        clients. Once you have registered, we reserve the right to send you certain communications relating to the
        Meteorcomm®&#39;s services, such as a reply to your request for information or comments, announcements,
        administrative messages, and promotions relating to Meteorcomm®&#39;s products and services.
        <br />
        <br />
    </p>
    <p>You will be offered the opportunity to opt-out of receiving future messages and announcements and remove your
        name from Meteorcomm® mailing lists. The opt-out provisions do not apply to information collected by cookies or
        used internally to recognize you and/or facilitate your visits to the extranet, or information we may retain to
        comply with legal requirements.<br /><br />Our extranet uses a software technology called &quot;cookies.&quot;
        Cookies are small text files that we place in visitors&#39; computer browsers to store their preferences.
        Cookies themselves do not contain any PII.<br /><br />&quot;Web beacons&quot; are small pieces of code placed on
        a web page to monitor the behavior and collect data about the visitors viewing a web page. For example, web
        beacons can be used to count the users who visit a web page or to deliver a cookie to the browser of a visitor
        viewing that page. We may use web beacons on our extranet from time to time for this
        purpose.<br /><br /><strong>Information Sharing and Disclosure</strong><br />Meteorcomm® does not rent, sell, or
        share PII about you with other people or nonaffiliated companies, except to provide products or services
        you&#39;ve requested, when we have your permission, or under the following circumstances: we provide the
        information to trusted partners who work on behalf of or with Meteorcomm® under confidentiality agreements.
        These companies may use your PII to help Meteorcomm® communicate with you about offers from Meteorcomm® and our
        marketing partners. However, these companies do not have any independent right to share this information.<br />
        We may disclose PII in response to legal process, for example, in response to a court order or a subpoena. We
        also may disclose PII in response to a law enforcement agency&#39;s request, or where we believe it is necessary
        to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving
        potential threats to the physical safety of any person, violations of our Terms of Use, to verify or enforce
        compliance with the policies governing our products and applicable laws, or as otherwise required or permitted
        by law or consistent with legal requirements. In addition, we may transfer your PII to an entity or individual
        that acquires, buys, or merges with Meteorcomm®, or Meteorcomm®&#39;s other business units.
        <br />
        <br />
        <strong>Confidentiality and Security</strong><br /><strong> </strong>We have taken steps to assure that all
        information collected will remain secure and in its original form, i.e., free from any alteration. As such,
        access to all personal information is strictly controlled. We have put in place appropriate physical,
        electronic, and managerial procedures in an effort to safeguard and help prevent unauthorized access, maintain
        data security, and correctly use the information we collect online.<br /><br />Meteorcomm® is not responsible
        for and will not be a party to any transactions between you and a third party provider of products, information
        or services. Meteorcomm® does not monitor such transactions to ensure the confidentiality of your credit card
        information. Any separate charges or obligations you incur in your dealings with these third parties linked to
        Meteorcomm®&#39;s extranet are your responsibility. Your California Privacy Rights if you are a California
        resident, California Civil Code Section 1798.83 permits you to request and obtain from us once a year, free of
        charge, information about the personal information (if any) we disclosed to third parties for direct marketing
        purposes in the preceding calendar year. If applicable, this information would include a list of the categories
        of personal information that was shared and the names and addresses of all third parties with which we shared
        information in the immediately preceding calendar year. If you do not want your name and contact information to
        be shared with any company or organization, you may notify us at any time by writing to us at the email and or
        regular mail address below.
        <br />
        <br />
        <strong>Questions and Suggestions</strong><br /> If you have questions or suggestions, or wish to correct your
        profile information, please fill out our <a href='contact'>contact form</a> to send us an
        email.<br /><br />Written communication should be sent to:<br /><br />Meteorcomm LLC
        <br />1201 SW 7th St.<br /> Renton, WA 98057&#160;<br /> ​​​<br />
    </p>
</div>`;

    const navigate = useNavigate();
    const _okTerms = () => {
        navigate(-1);
    }
    return <>
        {<Dialog
            modalProps={{ layerProps: { eventBubblingEnabled: false } }}
            styles={{
                main: {
                    selectors: {
                        ['@media (min-width: 480px)']: {
                            width: "calc(100vw - 80px)",
                            minWidth: "calc(100vw - 80px)",
                            maxWidth: "calc(100vw - 80px)"
                        },
                        ['@media (min-width: 1280px)']: {
                            width: 1200,
                            minWidth: 1200,
                            maxWidth: 1200
                        }
                    }
                }
            }}
            hidden={false}
            dialogContentProps={{ title: "Terms of Use", titleProps: { style: { textAlign: "center" } } }}             
            >
            <Stack tokens={{ childrenGap: 10 }}>
                <div style={{maxHeight: "calc(100vh - 250px)"}} dangerouslySetInnerHTML={{ __html: legalHTML }} className="legalTermsBox" />
                <Stack horizontal tokens={{ childrenGap: 10 }} className="legalTermsButtons">
                    <PrimaryButton text="Ok" onClick={() => _okTerms()} />
                </Stack>
            </Stack>
        </Dialog>}
    </>
}

export default TermsOfUse;