import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../elements/pageWrapper';
import { EmbedPDF } from '../elements/pdfViewer/EmbedPDF';
import { apiPrefix } from '../lib/helper';
import { useAuthenticationContext } from '../providers/authprovider';
import { useLoadContext } from '../providers/loadProvider';
import { makeApiCall } from '../services/apiService';

interface ISupportDesc {
    PageDescription: string;
}

const SFTP: React.FunctionComponent = () => {
    const loadContext = useLoadContext();
    const [error, setError] = useState<string>();
    const [file, setFile] = useState<Blob>();
    const [fileDesc, setFileDesc] = useState<ISupportDesc[]>();
    const authContext = useAuthenticationContext();
    const key = "SFTP";

    useEffect(() => {
        if (authContext.Result?.IsAuthenticated) {
            loadContext.SetLoadingMessage({message: "Loading..."});
            makeApiCall(`${apiPrefix}getSupportFile?key=${key}`, "GET").then(blob => {
                setFile(blob);
            }).catch(err => {
                console.error(err);
                setError("Unable to get support file")
            }).finally(() => {
                loadContext.SetLoadingMessage(undefined);
            })
        }
      }, [authContext?.Result]);

      useEffect(() => {
        setError(undefined);
        loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
        if (authContext.Result?.IsAuthenticated) {
            loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });     
            makeApiCall(`${apiPrefix}getSupportFileDesc?key=${key}`).then((files) => {
                setFileDesc(files[0].PageDescription);
            }).catch(err => {
                console.error(err);
                const message = err === 'Unauthorized' ? "You are not authorized to view this page" : "Error: See console for more information";                setError(message);
            }).finally(() => {
                loadContext.SetLoadingMessage(undefined);
            })
        }
        else if (!authContext.Pending) {
            setError("You must be logged in to view this page")
            loadContext.SetLoadingMessage(undefined);
        }
    }, [authContext?.Result]);

      return (
        <PageWrapper title='Secure File Transfer'>
            <>
                {!loadContext.LoadingMessage && <>
                    {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
                    {!error && fileDesc && <Stack className='pdfDescText'><Markdown>{fileDesc.toString()}</Markdown></Stack>}
                    {!error && <><Stack tokens={{childrenGap: 10}} className="pdfFrame">                        
                        {file && <EmbedPDF fileBlob={file} fileName={" .pdf"} showToolbar={false} />}
                        </Stack>
                        </>}
                </>}
            </>
        </PageWrapper>
    );
};

export default SFTP;
