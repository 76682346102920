/* eslint-disable @typescript-eslint/explicit-function-return-type */

import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

interface INavProvider {
    ActiveNode: string;
    Responsive: string;
    SetActiveNode: (activeNode:string) => void
    SetResponsive: (responsive:string) => void
}

export const NavContext = createContext<INavProvider>({
    ActiveNode: undefined,
    SetActiveNode: undefined,
    Responsive: undefined,
    SetResponsive: undefined
});

export const useNavContext = () => useContext(NavContext)

export function NavProvider({ children }: PropsWithChildren<{}>) {
    const [activeNode, setActiveNode] = useState("");
    const [responsive, setResponsive] = useState("");
    return (
        <NavContext.Provider value={{ ActiveNode:activeNode,SetActiveNode:setActiveNode, Responsive:responsive,SetResponsive:setResponsive }}>
            {children}
        </NavContext.Provider>
    );
}