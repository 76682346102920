import { MessageBar, MessageBarType, Pivot, PivotItem } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../elements/pageWrapper';
import { DetailsListPartsFiles } from '../elements/partsfilelist';
import { DetailsListPartsItems } from '../elements/partsitemlist';
import { apiPrefix } from '../lib/helper';
import { useAuthenticationContext } from '../providers/authprovider';
import { useLoadContext } from '../providers/loadProvider';
import { makeApiCall } from '../services/apiService';

export interface IPartsItem {
    Title: string;
    PartCategory: string;
    PartPrice: string;
    PartDescription: string;
}

export interface IPartsFile {
    Title: string;
    Category: string;
    FileRef: string;
    FileLeafRef: string;
}

const PartsFRU: React.FunctionComponent = () => {
    const [partsItems, setPartsItems] = useState<IPartsItem[]>();
    const [fruFileData, setPartsFiles] = useState<IPartsFile[]>();
    const [error, setError] = useState<string>();
    const authContext = useAuthenticationContext();
    const loadContext = useLoadContext();
    useEffect(() => {
        setError(undefined);
        loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
        if (authContext.Result?.IsAuthenticated) {
            loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });     
            Promise.all([makeApiCall(`${apiPrefix}getPartsFRUList`), makeApiCall(`${apiPrefix}getPartsFRUFiles`)]).then(([items, files]) => {
                setPartsItems(items);
                setPartsFiles(files.sort((a, b) => (a.FileLeafRef < b.FileLeafRef ? -1 : 1)));
            }).catch(err => {
                console.error(err);
                const message = err === 'Unauthorized' ? "You are not authorized to view this page" : "Error: See console for more information";                setError(message);
            }).finally(() => {
                loadContext.SetLoadingMessage(undefined);
            })
        }
        else if (!authContext.Pending) {
            setError("You must be logged in to view this page")
            loadContext.SetLoadingMessage(undefined);
        }
    }, [authContext?.Result]);

    return <PageWrapper title="Parts & FRUs">
        <>
        {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
                {(fruFileData?.length > 0 || partsItems?.length > 0) && <Pivot>
                    {fruFileData?.length > 0 && <PivotItem headerText='Parts and FRU Supporting Files'>
                        <DetailsListPartsFiles fruFileData={fruFileData}/>
                        </PivotItem>}
                    {partsItems?.length > 0 && <PivotItem headerText='Parts and FRU Catalog'>
                    <DetailsListPartsItems items={partsItems}/>
                        </PivotItem>
                    }
                    </Pivot>}
        </>
    </PageWrapper>
}

export default PartsFRU