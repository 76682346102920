import * as React from 'react';
import {
  DetailsList, DetailsListLayoutMode, IColumn, Link, MessageBar, MessageBarType, SelectionMode
} from '@fluentui/react';
import { useState } from 'react';
import { IPartsFile } from '../pages/partsfru';
import { useAuthenticationContext } from '../providers/authprovider';
import saveAs from 'file-saver';
import { apiPrefix } from '../lib/helper';
import { makeApiCall } from '../services/apiService';

export interface IDetailsListItem {
  key: string;
  name: string;
  category: string;
}

export const DetailsListPartsFiles: React.FunctionComponent<{ fruFileData: IPartsFile[] }> = ({ fruFileData }) => {

  const [error, setError] = useState<string>()
  const authContext = useAuthenticationContext()
  
  const downloadFile = (file: IPartsFile) => {
    setError(undefined);
    if (authContext.Result?.IsAuthenticated) {
      makeApiCall(`${apiPrefix}getDocumentAsBlob?webServerRelativeUrl=&documentUrl=${file.FileRef}`).then(doc => {
        saveAs(doc, file.FileLeafRef);
      }).catch(err => {
        console.log("Error downloading file", err);
        setError(`Unable to download file`);
      })
    }
  }
  
  const partsListColumns: IColumn[] = [
    { key: 'column1', name: 'Name', fieldName: 'FileLeafRef', minWidth: 100, onRender: (item: IPartsFile) => {
      return <Link onClick={(_ev) => {
        downloadFile(item);
      }}>{item.FileLeafRef}</Link>}
    },
    { key: 'column2', name: 'Category', fieldName: 'Category', minWidth: 100 },
  ];

  return (
    <div className='partsListFiles'>
      {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
      <DetailsList
        items={fruFileData}
        columns={partsListColumns}
        setKey="set"
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        selectionPreservedOnEmptyClick={true}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        checkButtonAriaLabel="select row"
      />
    </div>
  );

}