import { DefaultButton } from '@fluentui/react';
import React from 'react';
import { PageWrapper } from '../elements/pageWrapper';
import { baseUrl } from '../lib/helper';

const SessionExpired: React.FunctionComponent = () => { 
    const queryParams = new URLSearchParams(window.location.search);
    const redirect = queryParams.get("redirect");

    return <PageWrapper title="Session expired" center>
        <>
            <div>Your session has expired. You must log back in to continue.</div>
            <DefaultButton text='Log In' title='Login to Customer Partner Portal' iconProps={{ iconName: "User" }} href={`${baseUrl}/login/azuread?redirect=${redirect || "/"}`} />
        </>
    </PageWrapper>
}

export default SessionExpired