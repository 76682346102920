import * as React from 'react';
import {
    DetailsList, DetailsListLayoutMode, IColumn, Link, MessageBar, MessageBarType, SelectionMode
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useAuthenticationContext } from '../providers/authprovider';
import { apiPrefix, baseUrl } from '../lib/helper';
import { makeApiCall } from '../services/apiService';
import { useLoadContext } from '../providers/loadProvider';
import { PageWrapper } from '../elements/pageWrapper';

interface ITrainingFile {
    Title: string;
    FileRef: string;
    FileLeafRef: string;
    Modified: string;
}

const TrainingVideos: React.FunctionComponent = () => {
    const [trainingFileData, setTrainingFiles] = useState<ITrainingFile[]>();
    const [error, setError] = useState<string>();
    const authContext = useAuthenticationContext();
    const loadContext = useLoadContext();

    const trainingVideoColumns: IColumn[] = [
        {
            key: 'column1', name: 'Name', fieldName: 'FileLeafRef', minWidth: 100, onRender: (item: ITrainingFile) => {
                return <Link 
                download
                href={`${baseUrl}${apiPrefix}getDocumentAsStream?webServerRelativeUrl=&documentUrl=${item.FileRef}`}
                >{item.FileLeafRef}</Link>
            }
        },
        { key: 'column2', name: 'Modified', fieldName: 'Modified', minWidth: 100 },
    ];
    useEffect(() => {
        setError(undefined);
        loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
        if (authContext.Result?.IsAuthenticated) {
            loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
            makeApiCall(`${apiPrefix}getTrainingFiles`).then((files) => {
                setTrainingFiles(files.map(function (obj: ITrainingFile) {
                    return { Title: obj.Title, FileLeafRef: obj.FileLeafRef, FileRef: obj.FileRef, Modified: new Intl.DateTimeFormat('en-us').format(new Date(obj.Modified.substring(0, 10))) }
                }
                ).sort((a, b) => (a.FileLeafRef < b.FileLeafRef ? -1 : 1)));
            }).catch(err => {
                console.error(err);
                const message = err === 'Unauthorized' ? "You are not authorized to view this page" : "Error: See console for more information"; setError(message);
            }).finally(() => {
                loadContext.SetLoadingMessage(undefined);
            })
        }
        else if (!authContext.Pending) {
            setError("You must be logged in to view this page")
            loadContext.SetLoadingMessage(undefined);
        }
    }, [authContext?.Result]);

    return <PageWrapper title="Training Videos">
        <>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            {(trainingFileData?.length > 0 || trainingFileData?.length > 0) && <div className='trainingFiles'>
                <DetailsList
                    items={trainingFileData}
                    columns={trainingVideoColumns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                />
            </div>}
        </>
    </PageWrapper>
}

export default TrainingVideos