import { Dropdown, Icon, IDropdownOption, Label, MaskedTextField, MessageBar, MessageBarType, PrimaryButton, Stack, StackItem, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { PageWrapper } from '../elements/pageWrapper';
import { apiPrefix } from '../lib/helper';
import { useAuthenticationContext } from '../providers/authprovider';
import { makeApiCall } from '../services/apiService';

interface PostBody {
    DisplayName: string;
    FirstName: string;
    LastName: string;
    Phone: string;
    Email: string;
    Topic: string;
    Inquiry: string;
}

const Contact: React.FunctionComponent = () => {

    const authContext = useAuthenticationContext();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [topic, setTopic] = useState<string>();
    const [inquiry, setInquiry] = useState<string>();
    const [error, setError] = useState<string>();
    const [saving, setSaving] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const topics: string[] = ["Products", "Services", "News", "General"];

    const topicOptions: IDropdownOption[] = topics.map(t => ({ key: t, text: t }));

    const _onSubmit = () => {
        setError(undefined);
        setSaving(true);
        const postBody: PostBody = {
            DisplayName: authContext.Result.UserInfo.DisplayName,
            Email: authContext.Result.UserInfo.Email,
            FirstName: authContext.Result.UserInfo.FirstName,
            LastName: authContext.Result.UserInfo.LastName,
            Inquiry: inquiry,
            Phone: phoneNumber,
            Topic: topic
        }
        makeApiCall(`${apiPrefix}submitContactForm`, "POST", JSON.stringify(postBody)).then(() => {
            setSuccess(true);
        }).catch(err => {
            console.error(err);
            setError("There was an error submitting the form.")
        }).finally(() => {
            setSaving(false);            
        })
    }

    const _canSave = () => {
        return topic && inquiry && phoneNumber && !saving;
    }

    return <PageWrapper title="Contact Us">
        <>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            {success && <MessageBar messageBarType={MessageBarType.success}>Thank you. Your information has been submitted successfully.</MessageBar>}

            {!success && authContext.Result?.UserInfo ?
                <Stack tokens={{ childrenGap: 10 }}>
                    <TextField
                        label='Name'
                        readOnly
                        disabled
                        value={authContext.Result.UserInfo.DisplayName}
                    />
                    <TextField
                        label='Email'
                        readOnly
                        disabled
                        value={authContext.Result.UserInfo.Email}
                    />
                    <MaskedTextField
                        mask={"999-999-9999"}
                        label='Phone Number'
                        required
                        value={phoneNumber || ""}           
                        onChange={(_ev, value) => {
                            setPhoneNumber(value || "")                            
                        }}            
                        validateOnFocusOut={true}
                         />
                    <Dropdown
                        label='Topic'
                        options={topicOptions}
                        required
                        selectedKey={topic}
                        onChange={(_ev, option) => {
                            setTopic(option.key.toString() || "")
                        }} />
                    <TextField
                        label='Inquiry'
                        multiline
                        required
                        value={inquiry || ""}
                        onChange={(_ev, value) => {
                            setInquiry(value || "")
                        }} />
                    <Stack horizontal horizontalAlign="center">
                        <PrimaryButton disabled={!_canSave()} text='Submit' onClick={_onSubmit} />
                    </Stack>
                </Stack>
                : !success && !error && <MessageBar messageBarType={MessageBarType.error}>You must be logged in to view this page</MessageBar>}
        </>
    </PageWrapper>
}

export default Contact