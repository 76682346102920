import * as React from 'react';
import {
    DetailsList, DetailsListLayoutMode, IColumn, Icon, Link, MessageBar, MessageBarType, SelectionMode
} from '@fluentui/react';
import { useState } from 'react';
import { IWhitePaperFile } from '../pages/whitepapers';
import { useAuthenticationContext } from '../providers/authprovider';
import saveAs from 'file-saver';
import { apiPrefix } from '../lib/helper';
import {getFileTypeIconProps, initializeFileTypeIcons} from "@fluentui/react-file-type-icons";
import { makeApiCall } from '../services/apiService';

export const DetailsListWPFiles: React.FunctionComponent<{ whitePaperFileData: IWhitePaperFile[] }> = ({ whitePaperFileData }) => {

    initializeFileTypeIcons();
    const [error, setError] = useState<string>()
    const authContext = useAuthenticationContext()
    const downloadFile = (file: IWhitePaperFile) => {
        setError(undefined);
        if (authContext.Result?.IsAuthenticated) {
            makeApiCall(`${apiPrefix}getDocumentAsBlob?webServerRelativeUrl=&documentUrl=${file.FileRef}`).then(doc => {
                saveAs(doc, file.FileLeafRef);
            }).catch(err => {
                console.log("Error downloading file", err);
                setError(`Unable to download file`);
            })
        }
    }

    const wpListColumns: IColumn[] = [
        {
            key: 'column1', name: 'Name', fieldName: 'FileLeafRef', minWidth: 100, onRender: (item: IWhitePaperFile) => {
                return <><Icon className='detailsIconStyle' {...getFileTypeIconProps({ extension: item.FileLeafRef.substring(item.FileLeafRef.lastIndexOf(".") + 1), size: 24 })} />&nbsp;&nbsp;<Link onClick={(_ev) => {
                    downloadFile(item);
                }}>{item.FileLeafRef}</Link></>
            }
        },
        {
            key: 'column2', name: 'Modified', fieldName: 'Modified', minWidth: 100, onRender: (item: IWhitePaperFile) => {
                return <span>{new Intl.DateTimeFormat('en-us').format(new Date(item.Modified)).toLocaleString()}</span>
            }
        }
    ];

    // Groups
    function getDocSets() {
        return whitePaperFileData.map((item) => item.Category)
        .filter((value, index, self) => self.indexOf(value) === index).map((r, idx) => { 
            return { key: r, name: r, startIndex: whitePaperFileData.map(e => e.Category).indexOf(r), count: whitePaperFileData.filter((obj) => obj.Category === r).length, level: 0 } 
        });
    }

    return (
        <div className='researchListFiles'>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            <DetailsList
                items={[...whitePaperFileData]}
                columns={wpListColumns}
                groups={getDocSets()}
                groupProps={{
                    showEmptyGroups: false,
                    isAllGroupsCollapsed: true
                }}
                compact={false}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />
        </div>
    );

}