import { IStackTokens, Stack } from '@fluentui/react';
import React from 'react';

type IPageWrapperProps = {
    title: string;
    className?: string;
    center?: boolean;
    imagePath?: string;
    children: JSX.Element;
}

export const PageWrapper: React.FC<IPageWrapperProps> = ({ title, className, center, imagePath, children }) => {
    const GapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10
    };
    return <div>
        <div className="pageFlare">
            <div className="imgBox">
                <img src={`${process.env.PUBLIC_URL}${imagePath || "/img/products.png"}`} alt="Page Logo" />
            </div>
            <div className="homeTitleBox">{title}</div>
        </div>
        <Stack className={`pageBody ${className || ""}`}>
            {center ? <Stack tokens={GapStackTokens} horizontalAlign="center">{children}</Stack> : <>{children}</>}
        </Stack>
    </div>
}
