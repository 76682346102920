import { ProgressIndicator, Stack, MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { loadScript } from '../../lib/helper';
import ViewSDKClient from './ViewSDKClient';

export const EmbedPDF: FunctionComponent<{ fileBlob: Blob, fileName: string, showToolbar: boolean, height?: string, width?: string }> = (props) => {

    const { fileBlob, fileName, height, width, showToolbar } = props;
    const embedRef = React.useRef<HTMLDivElement | null>(null);
    const [error, setError] = React.useState<string | undefined>();

    const fileWrapperId = React.useMemo(() => {
        return `file-embed-wrapper-${fileName}-${showToolbar}`
    }, [fileName, showToolbar]);
    const hasRequestedPreview = React.useRef(false);

    const loadPdfClient = React.useCallback(() => {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            /* Invoke file preview */
            const viewOptions = {
                embedMode: "FULL_WINDOW",
                defaultViewMode: "FIT_WIDTH",
                showAnnotationTools: false,                
                showPrintPDF: showToolbar,
                showLeftHandPanel: false,
                showPageControls: showToolbar,
                showDownloadPDF: showToolbar,
                showThumbnails: showToolbar,
                showBookmarks: false,
                enableFormFilling: false,
                showZoomControl: showToolbar
            };
            if (embedRef.current && hasRequestedPreview.current === false) {
                hasRequestedPreview.current = true;
                viewSDKClient.previewFileUsingFilePromise(fileWrapperId, fileBlob.arrayBuffer(), fileName, viewOptions)
            }
        }).catch(err => {
            console.error(err);
            setError("Error loading PDF view SDK Client")
        });
    }, [fileBlob, fileName, fileWrapperId, showToolbar]);

    React.useEffect(() => {
        loadScript("https://documentcloud.adobe.com/view-sdk/viewer.js").then(() => {
            let intervalId = window.setInterval(() => {
                if ((window as any).AdobeDC) {
                    window.clearInterval(intervalId);
                    loadPdfClient();
                }
            }, 50)
        }).catch(err => {
            console.log(err);
            setError("There was an error loading the Adobe PDF Embed script");
        });
    }, [loadPdfClient, props.fileName]);

    return <div ref={embedRef} id={fileWrapperId} style={{ height: height || "calc(100vh - 44px)", width: "auto" }}>
        <Stack>
            {!error && <>
                <ProgressIndicator styles={{ itemProgress: { padding: 0 } }} />
            </>}
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
        </Stack>
    </div>
}