import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { IProduct } from '../types/iProduct';

interface IProductsProvider {
    Products: IProduct[];
    SetProducts: (products: IProduct[]) => void;
}

export const ProductsContext = createContext<IProductsProvider>({
    Products: undefined,
    SetProducts: undefined
});

export const useProductsContext = () => useContext(ProductsContext);

export function ProductsProvider({ children }: PropsWithChildren<{}>) {
    const [products, setProducts] = useState<IProduct[]>(undefined);    
    return (
        <ProductsContext.Provider value={{ Products: products, SetProducts: setProducts }}>
            {children}
        </ProductsContext.Provider>
    );
}