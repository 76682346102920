import { MessageBar, MessageBarType, Pivot, PivotItem, SelectionMode } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FolderFilePicker } from '../elements/folderFilePicker';
import { PageWrapper } from '../elements/pageWrapper';
import { apiPrefix } from '../lib/helper';
import { useAuthenticationContext } from '../providers/authprovider';
import { makeApiCall } from '../services/apiService';

export interface IFile {
    Name: string;
    ServerRelativeUrl: string;
}

export interface IProductPackage {
    FileLeafRef: string;
    Product: {
        _Revision: string;
        Title: string;
    },
    Folder: {
        ServerRelativeUrl: string;
        Name: string;
        Files: IFile[];
    },
    ReleaseDate: string;
}

interface IShareType {
    ReadOnlyEnabled: boolean;
    ReadWriteEnabled: boolean;
}

const Shares: React.FunctionComponent = () => {
    //const [productPackages, setProductPackages] = useState<IProductPackage[]>();
    const [error, setError] = useState<string>();
    const authContext = useAuthenticationContext();
    const param = useParams()?.pid;
    const paramID = typeof param === 'string' ? param : 'Product ID not specified'; /* Need to figure out how to deal with null value */
    const [shareStatus, setShareStatus] = useState<IShareType>();

    useEffect(() => {
        if (authContext?.Result?.IsAuthenticated) {
            makeApiCall(`${apiPrefix}getFileShares`).then(shares => {
                const share = shares.find(s => s.PartnerName === paramID);
                if (share) {
                    const readEnabled = share.ReadEnabled;
                    const readWriteEnabled = share.ReadWriteEnabled;
                    setShareStatus({
                        ReadOnlyEnabled: readEnabled,
                        ReadWriteEnabled: readWriteEnabled
                    })
                }
                else {
                    setError(`Unable to find share for '${paramID}'`);
                }
            }).catch(err => {
                console.error(err);
                setError("Unable to get share");
            })
        }
    }, [authContext.Result, paramID])

    return <PageWrapper title={paramID}>
        <>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            {paramID && shareStatus && <Pivot>

                {paramID && shareStatus?.ReadOnlyEnabled &&
                    <PivotItem headerText="Read Only Documents">
                        <FolderFilePicker
                            isReadOnly={true}
                            partnerTitle={paramID}
                            disabled={false}
                            selectionMode={SelectionMode.multiple}
                            showBorder={false}
                            key={paramID}
                            allowFileDownload={true} />
                    </PivotItem>
                }
                {paramID && shareStatus?.ReadWriteEnabled &&
                    <PivotItem headerText='Shared Documents'>
                        <FolderFilePicker
                            isReadOnly={false}
                            partnerTitle={paramID}
                            disabled={false}
                            selectionMode={SelectionMode.multiple}
                            showBorder={false}
                            key={paramID}
                            allowFileDownload={true} />
                    </PivotItem>
                }
            </Pivot>}

        </>
    </PageWrapper>
}

export default Shares