import { Dialog, MessageBar, MessageBarType, ProgressIndicator, Stack, TextField, PrimaryButton } from "@fluentui/react";
import React, { PropsWithChildren, useState } from "react";
import { apiPrefix } from "../lib/helper";
import { useAuthenticationContext } from "../providers/authprovider";
import { makeApiCall } from "../services/apiService";


export type NewFolderDialogProps = PropsWithChildren<{
    parentFolder: string;
    partnerTitle: string;
    onClose?: (refresh: boolean) => void;
}>

export function NewFolder(props: NewFolderDialogProps): JSX.Element {

    const [folderName, setFolderName] = useState<string>("");
    const [saving, setSaving] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const authContext = useAuthenticationContext();

    const _createFolder = (): void => {
        if (authContext?.Result?.IsAuthenticated) {
            setSaving(true); 
            makeApiCall(`${apiPrefix}createFolder?partnerTitle=${props.partnerTitle}&folderServerRelativeUrl=${props.parentFolder}&folderName=${folderName}`, "POST").then(() => {
                props.onClose(true);
            }).catch(err => {
                console.error(err);
                setError("There was an error creating the new folder. Check the console for more details.");
            }).finally(() => {
                setSaving(false);
            })
        }
    }

    return <Dialog
        hidden={false}
        onDismiss={() => props.onClose(false)}
        modalProps={{ isBlocking: true }}
        dialogContentProps={{ title: `New Folder` }}  >
        <Stack tokens={{ childrenGap: 10 }}>
            <TextField
                label="Folder Name"
                required
                disabled={saving}
                value={folderName}
                onChange={(_ev, newValue) => {
                    setFolderName(newValue || "")
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && folderName?.length > 0) {
                        event.preventDefault();
                        event.stopPropagation();
                        _createFolder();
                    }
                }}
            />
            {saving && <ProgressIndicator label="Creating folder..." />}
            <Stack.Item align="end">
                <PrimaryButton
                    style={{ width: "max-content" }}
                    text="Save"
                    disabled={saving || folderName?.length === 0}
                    iconProps={{ iconName: "Save" }}
                    onClick={_createFolder}
                />
            </Stack.Item>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
        </Stack>
    </Dialog>
}