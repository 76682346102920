import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { makeApiCall } from '../services/apiService';
import { IAuthStatusContext } from '../types/iauth';
import { IApiContext } from '../types/shared';

interface IAuthProviderContext extends IApiContext<IAuthStatusContext> {
    //might use this
}

export const AuthenticationContext = createContext<IAuthProviderContext>({ Pending: false });
export function useAuthenticationContext() {
    return useContext(AuthenticationContext);
}

export function AuthenticationProvider({ children }: PropsWithChildren<{}>) {

    const [authStatus, setAuthStatus] = useState<IAuthStatusContext>();
    const [error, setError] = useState();
    const [pending, setPending] = useState(true);

    const getAuthStatus = () => {
        return makeApiCall("/login/getstatus", "GET")
    }

    useEffect(() => {
        setError(undefined);
        if (window.location.pathname !== "/session-expired") {
            setPending(true);
            getAuthStatus().then((authResult: any) => {
                if (authResult.launchInfo && window.location.pathname !== "/session-expired") {
                    const authStatus: IAuthStatusContext = {
                        IsAuthenticated: authResult.launched,
                        IsAdmin: authResult.isCPPAdministrator,
                        ...authResult.launchInfo,                    
                        ExpiresOn: authResult.launchInfo.expiresOn
                    }
                    setAuthStatus(authStatus);
                }
                else {
                    setAuthStatus({
                        IsAuthenticated: false,
                        IsAdmin: false,                  
                        UserInfo: undefined,
                        ExpiresOn: undefined
                    })
                }
            }).catch((err: any) => {
                setError(err);
            }).finally(() => {
                setPending(false);
            });
        }
    }, []);

    return (
        <AuthenticationContext.Provider value={{ Error: error, Pending: pending, Result: authStatus }}>
            {children}
        </AuthenticationContext.Provider>
    );
}