import { IStackTokens, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import Collapse, { Panel } from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../elements/pageWrapper';
import { apiPrefix } from '../lib/helper';
import { useAuthenticationContext } from '../providers/authprovider';
import { useLoadContext } from '../providers/loadProvider';
import { makeApiCall } from '../services/apiService';

export interface IFAQItem {
    Title: string;
    FAQ: string;
}

const FAQ: React.FunctionComponent = () => {
    // Tokens definition for stack
    const GapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10
    };

    const [faqItems, setFaqItems] = useState<IFAQItem[]>();
    const [error, setError] = useState<string>();
    const authContext = useAuthenticationContext();
    const loadContext = useLoadContext();

    if (authContext.Result?.IsAuthenticated) {

    }

    useEffect(() => {
        setError(undefined);
        loadContext.SetLoadingMessage({ message: 'One Moment...', hideBody: true });
        if (authContext.Result?.IsAuthenticated) {
            setError(undefined);
            loadContext.SetLoadingMessage({ message: `Loading frequently asked questions...` });
            makeApiCall(`${apiPrefix}getFAQ`).then(faqData => {
                setFaqItems(faqData);
            }).catch(err => {
                console.error(err);
                const message = err === 'Unauthorized' ? 'You are not authorized to view this data' : 'Error: See console for more information';
                setError(message);
            }).finally(() => {
                loadContext.SetLoadingMessage(undefined);
            });

        }
        else if (!authContext.Pending) {
            setError('You must be logged in to view this page')
            loadContext.SetLoadingMessage(undefined);
        }
    }, [authContext?.Result, authContext?.Result?.IsAuthenticated, authContext.Pending]);

    return (
            <PageWrapper title="Frequently Asked Questions" className='faqBody' center>
                {!loadContext.LoadingMessage && <>
                    {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
                    {!faqItems && !error ? <MessageBar messageBarType={MessageBarType.info}>There doesn't seem to be any content available to you yet...</MessageBar> : <Collapse accordion={true} className='faqPanel'>
                        {faqItems && faqItems.map((item, idx) => <Panel header={item?.Title} key={item?.Title}><span dangerouslySetInnerHTML={{__html: item.FAQ}}/></Panel>)}
                    </Collapse>}
                </>}
            </PageWrapper>
    );
}

export default FAQ

function setError(arg0: string) {
    throw new Error('Function not implemented.');
}
