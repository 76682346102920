import { ConfirmDialog } from '@cloudwell/simple-dialog';
import { ActionButton, CommandBar, DetailsList, DetailsRow, getFocusStyle, getTheme, HoverCard, HoverCardType, IColumn, Icon, IconButton, ITheme, Link, mergeStyleSets, MessageBar, MessageBarType, PrimaryButton, ProgressIndicator, Selection, SelectionMode, ShimmeredDetailsList, Stack, StackItem, Sticky, StickyPositionType } from '@fluentui/react';
import {
    FileIconType, getFileTypeIconProps, initializeFileTypeIcons
} from "@fluentui/react-file-type-icons";
import { useConst } from '@fluentui/react-hooks';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { apiPrefix, humanFileSize } from '../lib/helper';
import { useAuthenticationContext } from '../providers/authprovider';
import { useLoadContext } from '../providers/loadProvider';
import { makeApiCall } from '../services/apiService';
import { NewFolder } from './newFolder';

export interface IDocumentsAndFolderResponse {
    documents: DocumentOrFolderRaw[];
}

export interface DocumentOrFolderRaw {
    Folder?: FolderOrFileProps | null;
    Author?: AuthorOrEditor;
    Editor?: AuthorOrEditor;
    FileDirRef: string;
    FileDirRefLower: string;
    FileLeafRef: string;
    Created?: string;
    Modified?: string;
    FileRef: string;
    FileRefLower: string;
    FSObjType: number;
    File?: FolderOrFileProps | null;
}


export interface FolderOrFileProps {
    ServerRelativeUrl: string;
    ServerRelativeUrlLower: string;
    TimeLastModified: string;
}

export interface AuthorOrEditor {
    ID: number;
    Title: string;
}

export enum DocumentFolderType {
    File = 0,
    Folder = 1
}

export interface IDocumentOrFolder {
    FolderOrFile: DocumentFolderType;
    FolderRelativeUrl: string;
    FileLeafRef: string;
    Modified: string;
    Created: string;
    FileSize?: number;
    key: string;
}

initializeFileTypeIcons();
const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;
const itemCellBase = {
    padding: 10,
    boxSizing: "border-box",
    display: "flex",
    fontSize: "22px"
};
const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1 }),
        {
            ...itemCellBase,
            selectors: {
                '&:hover': { background: palette.neutralLight }
            },
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            cursor: "pointer"
        }
    ],
    itemCellNoHover: itemCellBase,
    itemContent: {
        overflow: 'hidden',
        flexGrow: 1
    },
    itemName: [
        fonts.medium,
        {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    ],
});

export interface FolderFilePickerProps {
    serverRelativeUrl: string;
}

export interface EntityFilePickerProps {
    entityId: string;
}

const FolderFilePickerInternal: React.FC<{
    partnerTitle: string,
    isReadOnly: boolean,
    disabled: boolean,
    selectionMode: SelectionMode,
    allowFileDownload: boolean,
    showBorder?: boolean,
    startBelowNodePath?: string,
    minHeight?: number,
}> = ({
    partnerTitle,
    isReadOnly,
    selectionMode,
    disabled,
    allowFileDownload,
    showBorder,
    startBelowNodePath,
    minHeight
}) => {

        const [selectedOption, setSelectedOption] = useState<string>();
        const [loading, setLoading] = useState(true);
        const [isUploading, setIsUploading] = useState<boolean>(false);
        const [refreshFlag, setRefreshFlag] = useState<boolean>(false);
        const [error, setError] = useState<string | undefined>();
        const [options, setOptions] = useState<IDocumentOrFolder[]>([]);
        const [selectedFiles, setSelectedFiles] = React.useState([]);

        const loadingContext = useLoadContext();
        const authContext = useAuthenticationContext();
        const folderRelativeUrl = "";
        const _rootPath = useRef(folderRelativeUrl);
        const _startBelowNodePath = useRef<string | undefined>(startBelowNodePath);

        const selection = useConst(() => {
            const sel = new Selection({
                onSelectionChanged: () => {
                    setSelectedFiles(sel.getSelection());
                },
                canSelectItem: (item) => {
                    return (item as IDocumentOrFolder).FolderOrFile === 0;
                }
            })
            return sel;
        }
        );

        const _getAllDocumentsAndFolders = async (folderRelativeUrl: string): Promise<IDocumentOrFolder[]> => {
            setLoading(true);
            if (authContext.Result?.IsAuthenticated) {
                const qs = `partnerTitle=${partnerTitle}&folderRelativeUrl=${encodeURIComponent(folderRelativeUrl?.replace(/"/g, ""))}&isReadOnly=${isReadOnly}`;
                const apiPath: string = `${apiPrefix}getSharedDocuments?${qs}`;
                return makeApiCall(apiPath, "GET").then(res => {
                    if (res.error) {
                        throw res.error;
                    }
                    else {
                        return res.filter((d: any) => d.Name.toLowerCase() !== "forms").map((d: any) => {
                            let retVal: IDocumentOrFolder = {
                                FileLeafRef: d.Name,
                                FolderOrFile: d.IsFile ? DocumentFolderType.File : DocumentFolderType.Folder,
                                FolderRelativeUrl: d.FolderRelativeUrl,
                                Modified: d.Modified,
                                Created: d.Created,
                                FileSize: d.Size,
                                key: d.FolderRelativeUrl + d.Name
                            }
                            return retVal;
                        });
                    }
                }).catch(err => {

                }).finally(() => {
                    setLoading(false);
                });
            }
        }

        const _setNewFolder = async (url: string | undefined) => {
            setLoading(true);
            setError(undefined);
            _getAllDocumentsAndFolders(url).then(response => {
                setSelectedOption(url);
                setLoading(false);
                setOptions([...response || []].sort((a, b) => a.FolderOrFile === b.FolderOrFile ? (a.FileLeafRef > b.FileLeafRef ? 1 : -1) : (a.FolderOrFile === DocumentFolderType.Folder ? -1 : 1)));
            }).catch(err => {
                console.error(err);
                setError("Unable to set the new folder");
            }).finally(() => {
                setLoading(false);
            })
        }

        const _getParentUrl = (url: string) => {
            const parentUrl = _rootPath.current?.toLowerCase() !== url.toLowerCase() ? url.substring(0, url.lastIndexOf("/")) : undefined;
            return parentUrl;
        }

        const _getBreadCrumbTitle = (url: string) => {
            const siteCollectionUrl: string = url.split("/").slice(0, 3).join("/");
            const parentUrl = url.replace(siteCollectionUrl.toLowerCase(), "").substring(1).replace(/\//g, " / ").replace(/"/g, "");
            return parentUrl;
        }

        const zipFiles = () => {
            setError(undefined);
            if (authContext.Result?.IsAuthenticated && !loadingContext.LoadingMessage) {
                loadingContext.SetLoadingMessage({ message: `Downloading ${selectedFiles.length} documents as zip` });
                makeApiCall(`${apiPrefix}getDocumentsAsZip?partnerTitle=${partnerTitle}&isReadOnly=${isReadOnly}`, "POST", JSON.stringify({ fileUrls: selectedFiles.map(f => f.FolderRelativeUrl) })).then(zip => {
                    const dateNow = new Intl.DateTimeFormat('en-us').format(new Date()).replace(/\//g, "-");
                    const fileName = `Files - ${dateNow}`;
                    saveAs(zip, `${fileName}.zip`);
                }).catch(err => {
                    setError("There was an error downloading the zip file");
                    console.log(err);
                }).finally(() => {
                    loadingContext.SetLoadingMessage(undefined);
                })
            }
        }

        const _renderBreadcrumb = (): JSX.Element => {
            let retVal = <div />;
            if (selectedOption) {
                let folder = selectedOption;
                if (folder) {
                    const parentUrl = _getParentUrl(selectedOption);
                    retVal = <Stack horizontal title={_getBreadCrumbTitle(selectedOption)} style={{ marginRight: 10, paddingLeft: 10 }} verticalAlign='center'>
                        {folderRelativeUrl?.replace(/"/g, "") !== folder.replace(/"/g, "") &&
                            <StackItem>
                                <IconButton
                                    onClick={(ev) => {
                                        !disabled && _setNewFolder(parentUrl);
                                    }}
                                    iconProps={{ iconName: parentUrl ? "Up" : "Back" }} />
                            </StackItem>
                        }
                        <StackItem>
                            {<span>{_getBreadCrumbTitle(selectedOption)}</span>}
                        </StackItem>
                    </Stack>;
                }
            }
            return retVal;
        }

        const downloadFile = (serverRelativeUrl: string) => {
            setError(undefined);
            if (authContext.Result?.IsAuthenticated) {
                const path = `${apiPrefix}getDocumentAsBlobFromShare?webServerRelativeUrl=&documentUrl=${encodeURIComponent(serverRelativeUrl)}&isReadOnly=${isReadOnly}&partnerTitle=${partnerTitle}`;
                makeApiCall(path).then(doc => {
                    const name = serverRelativeUrl.substring(serverRelativeUrl.lastIndexOf("/"));
                    saveAs(doc, name);
                }).catch(err => {
                    console.log("Error downloading file", err);
                    setError(`Unable to download file`);
                }).finally(() => {

                })
            }
        }

        const _onRenderDestinationListCell = (option?: IDocumentOrFolder) => {
            let divProps: any = { className: classNames.itemCellNoHover };
            if (option!.FolderOrFile === DocumentFolderType.Folder) {
                divProps["data-is-focusable"] = true;
                divProps.onClick = () => {
                    _setNewFolder(option!.FolderRelativeUrl);
                }
            }
            const fileType = option?.FolderRelativeUrl.substring(option.FolderRelativeUrl.lastIndexOf(".") + 1);
            const filePath = option!.FolderRelativeUrl;
            const title = allowFileDownload && option?.FolderOrFile === DocumentFolderType.File ? <Link onClick={() => {
                downloadFile(filePath);
            }}>{option?.FileLeafRef}</Link> : option?.FileLeafRef;

            return (
                <Stack horizontal verticalAlign="center">
                    {option?.FolderOrFile === DocumentFolderType.Folder && <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 24 })} />}
                    {option?.FolderOrFile === DocumentFolderType.File && <Icon {...getFileTypeIconProps({ extension: fileType, size: 24 })} />}
                    <span {...divProps} style={{ whiteSpace: "normal", paddingTop: 0, paddingBottom: 0, fontSize: 14, cursor: option?.FolderOrFile === DocumentFolderType.Folder ? "pointer" : "initial" }}>{title}</span>
                </Stack>);
        }

        const _loadTopLevelFolderOptions = React.useCallback((folder?: string) => {
            setLoading(true);
            if (authContext.Result?.IsAuthenticated) {
                _getAllDocumentsAndFolders(folder || folderRelativeUrl).then((documentsAndFolders: IDocumentOrFolder[]) => {
                    const currentOptionsPromise = _startBelowNodePath.current && _getAllDocumentsAndFolders(_startBelowNodePath.current);
                    _rootPath.current = options.length > 0 ? options[0].FolderRelativeUrl : undefined;
                    if (currentOptionsPromise) {
                        currentOptionsPromise.then(response => {
                            response = response.sort((a, b) => a.FolderOrFile === b.FolderOrFile ? (a.FileLeafRef > b.FileLeafRef ? 1 : -1) : (a.FolderOrFile === DocumentFolderType.Folder ? -1 : 1));
                            setOptions([...response])
                        }).catch(err => {
                            console.error(err);
                            setError("Unable to get options");
                        }).finally(() => {
                            setLoading(false);
                        })

                    }
                    else {
                        setLoading(false);
                        let options = [...documentsAndFolders];
                        options = options.sort((a, b) => a.FolderOrFile === b.FolderOrFile ? (a.FileLeafRef > b.FileLeafRef ? 1 : -1) : (a.FolderOrFile === DocumentFolderType.Folder ? -1 : 1));

                        setOptions(options);
                    }
                }).catch(err => {
                    setLoading(false);
                    console.error(err);
                    setError("Unable to get documents and folders");
                })
            }
            else if (!authContext.Pending) {
                setError("You must be logged in to view this page")
                setLoading(false);
            }
        }, [authContext.Result])

        useEffect(_loadTopLevelFolderOptions, [authContext.Result])

        useEffect(() => _loadTopLevelFolderOptions(selectedOption), [refreshFlag, _loadTopLevelFolderOptions]);

        const _onRenderPlainCardDetailsHeader = (props: any, renderer: any) => {
            return <Sticky stickyPosition={StickyPositionType.Header} componentRef={renderer(props)} />;
        };

        const _onRenderPlainCard = (): JSX.Element => {
            const items = selectedFiles;
            const itemListHeight = 44 + (items.length * 44);
            return <div style={{ position: "relative", width: 500, height: Math.min(itemListHeight, (minHeight ? minHeight + 8 : 308)), border: '1px solid rgba(198, 198, 198, .6)' }}>
                <DetailsList
                    onRenderDetailsHeader={_onRenderPlainCardDetailsHeader}
                    columns={[
                        {
                            key: "type",
                            name: "Type",
                            fieldName: "type",
                            iconName: "Page",
                            isIconOnly: true,
                            minWidth: 16,
                            maxWidth: 16,
                            onRender: (item: IDocumentOrFolder) => {
                                let fieldValue = null;
                                switch (item.FolderOrFile) {
                                    case DocumentFolderType.Folder: {
                                        fieldValue = <Icon iconName="FabricFolderFill" styles={{ root: { display: "contents" } }} />;
                                        break;
                                    }
                                    case DocumentFolderType.File: {
                                        const fileType = item.FileLeafRef?.split(".").pop();
                                        fieldValue = <Icon
                                            {...getFileTypeIconProps({ extension: fileType, size: 16 })}
                                            styles={{ root: { display: "contents" } }} />;
                                        break;
                                    }
                                }
                                return fieldValue;
                            }
                        },
                        {
                            key: "Name",
                            name: "Name",
                            fieldName: "FileLeafRef",
                            minWidth: 50,
                            onRender: (doc: IDocumentOrFolder) => {
                                const filePath = _getBreadCrumbTitle(doc.FolderRelativeUrl);
                                return <span title={filePath}>{filePath}</span>;
                            }
                        }
                    ]}
                    items={items}
                    selectionMode={SelectionMode.none} />

            </div>
        };

        const [itemDeleting, setItemDeleting] = useState<string>();
        const [removeFileOrFolderConfirm, setRemoveFileOrFolderConfirm] = useState<IDocumentOrFolder>();

        const _columns: IColumn[] = [{
            key: "Name",
            minWidth: 100,
            name: "Name",
            onRender: _onRenderDestinationListCell,
        },
        {
            key: "Modified",
            minWidth: 150,
            name: "Modified",
            onRender: (item: IDocumentOrFolder) => {
                return <span style={{ fontSize: 14, lineHeight: "40px" }}>{new Intl.DateTimeFormat('en-us').format(new Date( item.FolderOrFile === DocumentFolderType.Folder ? item.Created : item.Modified  )).toLocaleString()}</span>
            }
        },
        {
            key: "Size",
            name: "Size",
            fieldName: "FileSize",
            minWidth: 70,
            onRender: (doc: IDocumentOrFolder) => {
                return doc.FolderOrFile === DocumentFolderType.File && <span style={{ fontSize: 14, lineHeight: "40px" }}>{humanFileSize(doc.FileSize)}</span>;
            }
        },
        !isReadOnly && {
            key: "Delete",
            minWidth: 150,
            name: "Delete",
            onRender: (item: IDocumentOrFolder) => {
                return <IconButton
                    disabled={itemDeleting === item.FileLeafRef}
                    key={item.key}
                    iconProps={{ iconName: "Delete" }} onClick={() => {
                        setRemoveFileOrFolderConfirm(item);
                    }} />
            }
        }].filter(c => c)

        const onFileUpload = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent): void => {
            setIsUploading(true);
            const uploadFolder = selectedOption || "";
            if (authContext.Result?.IsAuthenticated) {
                const data: FormData = new FormData()
                for (let i = 0; i < acceptedFiles.length; i++) {
                    data.append('file', acceptedFiles[i])
                }
                const path = `${apiPrefix}uploadFile?folderServerRelativeUrl=${encodeURIComponent(uploadFolder)}&partnerTitle=${partnerTitle}`;
                makeApiCall(path, "POST", data, undefined, true).then(result => {
                    console.log(result);
                })
                    .catch(err => {
                        console.log(err);
                        return undefined;
                    }).finally(() => {
                        setIsUploading(false);
                        setRefreshFlag(!refreshFlag);
                    })
            }
        }

        const renderDocumentsDetailsList = () => <ShimmeredDetailsList
            enableShimmer={loading}
            compact={false}
            selectionMode={selectionMode}
            columns={_columns}
            styles={{
                root: {
                    ".ms-DetailsRow-cell": {
                        paddingBottom: 0
                    }
                }
            }}
            items={[...options]}
            onShouldVirtualize={() => false}
            selection={selection}
            setKey={"multiple"}
            onRenderRow={(props: any) => <DetailsRow {...props} data-selection-disabled={true} onClick={(ev?: any) => {
                if (ev.target.classList.contains('ms-Check-check')) {
                    selection.setIndexSelected(props.itemIndex, !selection.isIndexSelected(props.itemIndex), false)
                }
            }} />}
        />

        const fileUploadRef = React.createRef<HTMLInputElement>();

        //drag/drop
        const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
            onDrop: onFileUpload,
            noClick: true
        });

        const [newFolderDialog, setNewFolderDialog] = useState<boolean>();

        return <>
            {(selectedOption || selectedFiles.length > 0 || !isReadOnly) &&
                <Stack className="commandBarStyle" horizontal horizontalAlign='space-between'>
                    {_renderBreadcrumb()}
                    <CommandBar items={[]} farItems={[
                        !isReadOnly && selectedFiles.length === 0 && {
                            key: "upload",
                            iconProps: { iconName: "Upload" },
                            text: "Upload",
                            onClick: () => fileUploadRef.current.click()
                        },
                        !isReadOnly && selectedFiles.length === 0 && {
                            key: "createFolder",
                            iconProps: { iconName: "NewFolder" },
                            text: "Create Folder",
                            onClick: () => setNewFolderDialog(true)
                        },
                        selectedFiles.length > 0 && {
                            key: "selector",
                            style: { marginLeft: 10, marginRight: 10 },
                            commandBarButtonAs: () => {
                                return <div><HoverCard cardOpenDelay={500} type={HoverCardType.plain} plainCardProps={{
                                    onRenderPlainCard: _onRenderPlainCard
                                }}>
                                    <ActionButton
                                        iconProps={{ iconName: 'Clear' }}
                                        styles={{ root: { backgroundColor: '#fff',paddingTop:3 } }}
                                        onClick={() => { selection.setItems([]) }}>{`${selectedFiles.length} Selected`}</ActionButton>
                                </HoverCard></div>

                            }
                        },
                        selectedFiles.length > 0 && {
                            key: "zip",
                            commandBarButtonAs: () => {
                                return <PrimaryButton
                                    disabled={loadingContext.LoadingMessage !== undefined}
                                    styles={{ root: { marginTop: 6 } }}
                                    iconProps={{ iconName: "Download" }}
                                    text={selectedFiles.length > 1 ? `Download Zip (${selectedFiles.length} documents)` : "Download"}
                                    onClick={() => {
                                        if (selectedFiles.length > 1) {
                                            zipFiles();
                                        }
                                        else {
                                            downloadFile(selectedFiles[0].FolderRelativeUrl);
                                        }
                                    }}
                                />
                            }
                        }
                    ]?.filter(c => c)} />
                </Stack>
            }
            {isUploading && <ProgressIndicator label={`Uploading ${acceptedFiles.length} document(s)`} />}
            {<>
                {isReadOnly ? renderDocumentsDetailsList() : <div style={isDragActive ? { border: '2px solid', borderStyle: 'dashed' } : { padding: 2 }} {...getRootProps()}>
                    {renderDocumentsDetailsList()}
                    {!isDragActive && !isUploading && <Stack horizontalAlign="space-around" verticalAlign="space-around" tokens={{ padding: 10 }} grow>Drag and drop files here...</Stack>}
                    {isUploading && <ProgressIndicator label={`Uploading ${acceptedFiles.length} document(s)`} />}
                    <input {...getInputProps()} ref={fileUploadRef} style={{ display: "none" }} />
                </div>}
                {!error && !loading && !(options?.length > 0) && <>
                    <div className={classNames.itemCellNoHover} data-is-focusable={false} >
                        <div className={classNames.itemContent}>
                            <div className={classNames.itemName}>
                                <span>This folder doesn't have any subfolders or files.</span>
                            </div>
                        </div>
                    </div>
                </>}
                {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            </>}
            {newFolderDialog && <NewFolder partnerTitle={partnerTitle} parentFolder={selectedOption || "/"} onClose={(refresh) => {
                setNewFolderDialog(undefined);
                if (refresh) {
                    setRefreshFlag(!refreshFlag);
                }
            }} />}
            {removeFileOrFolderConfirm && <ConfirmDialog
                title={`Delete ${removeFileOrFolderConfirm.FolderOrFile === DocumentFolderType.File ? "File" : "Folder"}`}
                message={`Are you user you want to delete '${removeFileOrFolderConfirm.FileLeafRef}'?`}
                key={removeFileOrFolderConfirm.key}
                onClose={(result) => {
                    setError(undefined)
                    if (result) {
                        setItemDeleting(removeFileOrFolderConfirm.FileLeafRef);
                        const path = `${apiPrefix}delete${removeFileOrFolderConfirm.FolderOrFile === DocumentFolderType.File ? "File" : "Folder"}?${removeFileOrFolderConfirm.FolderOrFile === DocumentFolderType.File ? "file" : "folder"}ServerRelativeUrl=${encodeURIComponent(removeFileOrFolderConfirm.FolderRelativeUrl)}&partnerTitle=${partnerTitle}`;
                        makeApiCall(path, "DELETE").then(result => {
                            console.log(result);
                            setRefreshFlag(!refreshFlag);
                            setRemoveFileOrFolderConfirm(undefined);
                        })
                            .catch(err => {
                                console.log(err);
                                setError("There was an error deleting the file or folder")
                            }).finally(() => {
                                setItemDeleting(undefined);
                            })
                    }
                    else {
                        setRemoveFileOrFolderConfirm(undefined);
                    }
                }}
            />}
        </>
    }

function documentsPromiseChanged(prevProps: any, nextProps: any) {
    return true;
}

export const FolderFilePicker = React.memo(FolderFilePickerInternal, documentsPromiseChanged);