import {
    DetailsList, DetailsListLayoutMode, IColumn, Icon, Link, MessageBar, MessageBarType, SelectionMode
} from '@fluentui/react';
import saveAs from 'file-saver';
import * as React from 'react';
import { useState } from 'react';
import { apiPrefix } from '../lib/helper';
import { IResearchFile } from '../pages/research';
import { useAuthenticationContext } from '../providers/authprovider';

import { getFileTypeIconProps, initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import { makeApiCall } from '../services/apiService';
export const DetailsListResearchFiles: React.FunctionComponent<{ researchFileData: IResearchFile[] }> = ({ researchFileData }) => {
    initializeFileTypeIcons();


    const [error, setError] = useState<string>()
    const authContext = useAuthenticationContext()

    const downloadFile = (file: IResearchFile) => {
        setError(undefined);
        if (authContext.Result?.IsAuthenticated) {
            makeApiCall(`${apiPrefix}getDocumentAsBlob?webServerRelativeUrl=&documentUrl=${file.FileRef}`).then(doc => {
                saveAs(doc, file.FileLeafRef);
            }).catch(err => {
                console.log("Error downloading file", err);
                setError(`Unable to download file`);
            })
        }
    }

    const researchListColumns: IColumn[] = [
        {
            key: 'column1', name: 'Name', fieldName: 'FileLeafRef', minWidth: 100, onRender: (item: IResearchFile) => {
                return <><Icon className='detailsIconStyle' {...getFileTypeIconProps({ extension: item.FileLeafRef.substring(item.FileLeafRef.lastIndexOf(".") + 1), size: 24 })} />&nbsp;&nbsp;<Link onClick={(_ev) => {
                    downloadFile(item);
                }}>{item.FileLeafRef}</Link></>
            }
        },
        {
            key: 'column2', name: 'Modified', fieldName: 'Modified', minWidth: 100, onRender: (item: IResearchFile) => {
                return <span>{new Intl.DateTimeFormat('en-us').format(new Date(item.Modified)).toLocaleString()}</span>
            }
        }
    ];

    // Groups
    function getDocSets() {
        return researchFileData.map((item) => item.Group)
        .filter((value, index, self) => self.indexOf(value) === index).map((r, idx) => { 
            return { key: r, name: r, startIndex: researchFileData.map(e => e.Group).indexOf(r), count: researchFileData.filter((obj) => obj.Group === r).length, level: 0 } 
        });
    }

    return (
        <div className='researchListFiles'>
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            <DetailsList
                items={researchFileData}
                columns={researchListColumns}
                groups={getDocSets()}
                groupProps={{
                    showEmptyGroups: false,
                    isAllGroupsCollapsed: true
                }}
                compact={false}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />
        </div>
    );

}