import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './mainapp';
import { AuthenticationProvider } from './providers/authprovider';
import { LoadProvider } from './providers/loadProvider';
import { thisIsDevEnvironment } from './lib/helper';
import './styles/index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const Container = thisIsDevEnvironment ? React.StrictMode : React.Fragment;

root.render(
  <Container>
    <LoadProvider>
      <AuthenticationProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthenticationProvider>
    </LoadProvider>
  </Container>
);

