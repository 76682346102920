import {
    DetailsList, DetailsListLayoutMode, IColumn, SelectionMode
} from '@fluentui/react';
import * as React from 'react';
import { IPartsItem } from '../pages/partsfru';

export interface IDetailsListItem {
    key: string;
    name: string;
    category: string;
}

export const DetailsListPartsItems: React.FunctionComponent<{ items: IPartsItem[] }> = ({ items }) => {

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    function getGroups() {
        return items
        .sort((a, b) => ((a.PartCategory === b.PartCategory ? (a.PartDescription < b.PartDescription ? -1 : 1) : (a.PartCategory < b.PartCategory ? -1 : 1))))
        .reduce((acc, cur) => {
            const group = {
              key: cur.PartCategory,
              name: cur.PartCategory,
              startIndex: 0,
              count: 1,
            }
            if (acc.length === 0) {
              acc.push(group)
              return acc
            } else if (acc[acc.length - 1].key !== cur.PartCategory) {
              const { count, startIndex } = acc[acc.length - 1]
              acc.push({
                ...group,
                startIndex: count + startIndex,
              })
              return acc
            }
            acc[acc.length - 1].count++
            return acc
          }, [])
      }

    const partsListColumns: IColumn[] = [
        { key: 'column1', name: 'Name', fieldName: 'PartDescription', minWidth: 100 },
        { key: 'column2', name: 'Number', fieldName: 'Title', minWidth: 100 },
        { key: 'column3', name: 'Price', fieldName: 'PartPrice', minWidth: 100, onRender: (item: IPartsItem) => {
            return !isNaN(Number(item.PartPrice)) && USDollar.format(Number(item.PartPrice))
        } }
    ];

    return (
        <div className='partsListFiles'>
            <DetailsList
                items={items}
                groups={getGroups()}
                columns={partsListColumns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                onShouldVirtualize={ () => false }
            />
        </div>
    );

}