import { DefaultButton } from '@fluentui/react';
import React from 'react';
import { useAuthenticationContext } from '../providers/authprovider';
import { baseUrl } from '../lib/helper';
import { PageWrapper } from '../elements/pageWrapper';


const Home: React.FunctionComponent = () => {

    const authContext = useAuthenticationContext();
    return <PageWrapper title="CUSTOMER PARTNER PORTAL" center imagePath="img/cpp-home.png" className="homagePageContent">
        <>
            <p>Welcome to the Meteorcomm Customer Partner Portal</p>
            <p>Meteorcomm is the rail industry's top trusted choice for wireless communications technology that reliably transports mission-critical information. With a robust data backbone that is integrated into the railroads' IT infrastucture, Meteorcomm delivers innovative solutions that enable Positive Train Control (PTC) compliance and improve operational efficiencies.</p>
            <p>The Customer Partner Portal (CPP) is a dedicated site that provides Customers and Partners with access to ITCnet® products.</p>
            {!authContext.Result?.IsAuthenticated ? <DefaultButton text='Log In' title='Login to Customer Partner Portal' iconProps={{iconName: "Lock"}} href={`${baseUrl}/login/azuread?redirect=${window.location.pathname}`} /> : ''}
        </>
    </PageWrapper>
}

export default Home