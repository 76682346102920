import { PartialTheme } from '@fluentui/react';

export const AppTheme: PartialTheme = {
    palette: {
        themePrimary: '#b52c3a',
        themeLighterAlt: '#fcf4f5',
        themeLighter: '#f3d6d9',
        themeLight: '#e9b4b9',
        themeTertiary: '#d3737c',
        themeSecondary: '#be3f4c',
        themeDarkAlt: '#a32733',
        themeDark: '#8a212b',
        themeDarker: '#651820',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#ffffff',
      },
      defaultFontStyle: { fontFamily: 'Poppins, sans-serif' },
  };