import {
    CommandBar,
    DetailsHeader,
    DetailsList, Dropdown as FluentDropdown, IColumn,
    IDetailsHeaderProps,
    IGroup, IRenderFunction, Label, Link, MarqueeSelection, MessageBar,
    MessageBarType, PrimaryButton, Selection, SelectionMode, Spinner, Stack
} from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import { saveAs } from 'file-saver';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnChangeValue, default as ReactSelect } from "react-select";
import { apiPrefix, humanFileSize } from '../lib/helper';
import { IFile, IProductLine, IProductPackage } from '../pages/product';
import { useAuthenticationContext } from '../providers/authprovider';
import { useLoadContext } from '../providers/loadProvider';
import { useProductsContext } from '../providers/productsProvider';
import { makeApiCall } from '../services/apiService';
import { IProduct } from '../types/iProduct';

export interface IDetailsListGroupedItem {
    key: string;
    name: string;
    releaseDate: string;
    isDownloading: boolean;
    Length: string;
}

export interface IDetailsListGroupedState {
    items: IDetailsListGroupedItem[];
    groups: IGroup[];
    showItemIndexInView: boolean;
}

export const DetailsListProduct: React.FunctionComponent<{ productPackages: IProductPackage[], productLine: string, releaseId: string | undefined, productLineData: IProductLine[] }> = ({ productPackages, productLine, releaseId, productLineData }) => {
    const [error, setError] = React.useState<string>();
    const authContext = useAuthenticationContext();
    const loadingContext = useLoadContext();
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [product, setProduct] = React.useState<IProduct>();
    const productsContext = useProductsContext();
    const navigate = useNavigate();
    const selection = useConst(() => {
        const sel = new Selection({
            onSelectionChanged: () => {
                setSelectedFiles(sel.getSelection());
            }
        })
        return sel;
    }
    );

    React.useEffect(() => {
        if (productsContext.Products) {
            const product = productsContext.Products.find(p => p.ProductLine.Title === productLine)
            setProduct(product);
        }
    }, [productsContext.Products, productLine])

    const selectedRevision = useMemo(() => {
        setError(undefined);
        return productPackages.find(p => p.Product._Revision === releaseId)?.Product?._Revision || productPackages[0].Product._Revision
    }, [productPackages, releaseId])

    const getFiles = React.useCallback((): IDetailsListGroupedItem[] => {
        // Filter data for revision
        const allDocSets = productPackages.filter((obj) => obj.Product._Revision === selectedRevision);
        if (allDocSets.length > 0) {
            // Get Release date and convert from ISO
            const releaseDateVal = allDocSets[0].ReleaseDate ? new Intl.DateTimeFormat('en-us').format(new Date(allDocSets[0].ReleaseDate)).toLocaleString() : '';
            let retVal: IFile[] = [];
            //Get files from doc set data and flatten
            allDocSets.filter(d => d.Folder.Files.length > 0).forEach((ds, idx) => {
                retVal = [...retVal, ...ds.Folder.Files.map(f => ({ ...f, FolderName: ds.Folder.Name }))];
            });
            return retVal.map((f: IFile, idx) => ({
                key: f.ServerRelativeUrl,
                name: f.Name,
                releaseDate: releaseDateVal,
                isDownloading: false,
                Length: f.Length,
                FolderName: f.FolderName
            })).sort((a, b) => (a.FolderName < b.FolderName ? -1 : a.FolderName === b.FolderName ? ((a.key < b.key) ? -1 : 1) : 1));
        }
        else {
            return []
        }
    }, [productPackages, selectedRevision]);

    const [items, setItems] = useState<IDetailsListGroupedItem[]>(getFiles());

    React.useEffect(() => {
        const files = getFiles();
        setItems([...files]);
    }, [getFiles]);

    const _columns: IColumn[] = [
        {
            key: 'name', name: 'Release', fieldName: 'name', minWidth: 100, isResizable: false, onRender: (item: IDetailsListGroupedItem) => {
                return <Stack horizontal tokens={{ childrenGap: 6 }}>
                    <Link disabled={item.isDownloading}
                        onClick={(_ev) => {
                            downloadFile(item);
                        }}>{item.name}</Link>
                    {item.isDownloading && <Spinner />}
                </Stack>
            }
        },
        { key: 'releaseDate', name: 'Release Date', fieldName: 'releaseDate', minWidth: 100, isResizable: false },
        {
            key: 'size', name: 'Size', fieldName: 'length', minWidth: 80, isResizable: false, onRender: (item: IDetailsListGroupedItem) => {
                return <span>{humanFileSize(item.Length)}</span>
            }
        }
    ];

    // Groups
    function getDocSets(revision: string) {
        return productPackages
            .filter((obj) => obj.Product._Revision === revision && obj.Folder.Files.length > 0)
            .sort((a, b) => (a.Folder.ServerRelativeUrl < b.Folder.ServerRelativeUrl) ? -1 : 1)
            .map((p, idx, data) => ( /* startIndex uses previous group count for detailsList to determine position in data */
                {
                    key: p.Folder.ServerRelativeUrl,
                    name: p.Folder.Name,
                    startIndex: idx > 0 ? (data.filter((_, index) => index < idx)).reduce((sum, el) => sum += el.Folder.Files.length, 0) : 0,
                    count: p.Folder.Files.length, level: 0
                }
            ));
    }

    const downloadFile = (file: IDetailsListGroupedItem) => {
        setError(undefined);
        if (authContext.Result?.IsAuthenticated && !file.isDownloading) {
            let newItems = [...items];
            items.find(i => i.key === file.key).isDownloading = true;
            setItems(newItems)
            makeApiCall(`${apiPrefix}getDocumentAsBlob?webServerRelativeUrl=&documentUrl=${file.key}`).then(doc => {
                saveAs(doc, file.name);
            }).catch(err => {
                console.log("Error downloading file", err);
                setError(`Unable to download file`);
            }).finally(() => {
                let newItems = [...items];
                items.find(i => i.key === file.key).isDownloading = undefined;
                setItems(newItems)
            })
        }
    }

    const zipFile = () => {
        setError(undefined);
        if (authContext.Result?.IsAuthenticated && !loadingContext.LoadingMessage) {
            loadingContext.SetLoadingMessage({ message: `Downloading ${selectedFiles.length} documents as zip` });
            makeApiCall(`${apiPrefix}getDocumentsAsZip`, "POST", JSON.stringify({ fileUrls: selectedFiles.map(f => f.key) })).then(zip => {
                const dateNow = new Intl.DateTimeFormat('en-us').format(new Date()).replace(/\//g, "-");
                const fileName = `${productPackages[0].Product.Title} - ${selectedRevision} - ${dateNow}`;
                saveAs(zip, `${fileName}.zip`);
            }).catch(err => {
                setError("There was an error downloading the zip file");
                console.log(err);
            }).finally(() => {
                loadingContext.SetLoadingMessage(undefined);
            })
        }
    }

    const _getKey = (item: any, index?: number): string => {
        return item.key;
    }

    const versionOptions = Array.from(new Set(productPackages.map((item) => item.Product))).map(p => ({ key: p._Revision, text: (p.StatusCalculated?.toUpperCase() === "PUBLISHED") ? p._Revision : (p._Revision + " (Not Published)") })).filter((v, i, a) => a.findIndex(v2 => (v2.text === v.text)) === i);
    const selectVO = versionOptions.map(vo => ({ label: vo.text, value: vo.key.toString() }));
    return <Stack>
        {error && <MessageBar styles={{ root: { marginBottom: 10 } }} messageBarType={MessageBarType.error}>{error}</MessageBar>}
        <div>
            <CommandBar items={[{
                key: "versions",
                commandBarButtonAs: () => {
                    return <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Label>Version</Label>
                        <ReactSelect
                            menuPosition="fixed"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: 'black',
                                    height: 32,
                                    minHeight: 32,
                                    borderRadius: 2
                                }),
                                dropdownIndicator: styles => ({
                                    ...styles,
                                    color: "#666",
                                    marginTop: -2
                                }),
                                valueContainer: (base) => ({
                                    ...base,
                                    marginTop: -1,
                                    paddingTop: 0
                                }),
                                menuPortal: base => ({
                                    ...base,
                                    zIndex: 1000000
                                })
                            }}
                            // menuPlacement="bottom"
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            options={selectVO as any}
                            value={selectVO.filter(function (option) {
                                return option.value === selectedRevision;
                            })}
                            onChange={(option: OnChangeValue<any, any>) => {
                                navigate(`/product/${productLine}/${option.value}`);
                            }} />
                    </Stack>
                }
            },
            productLineData && productLineData[0]?.RelatedProductLines && productLineData[0]?.RelatedProductLines?.length > 0 && {
                key: "relatedProducts",
                commandBarButtonAs: () => {
                    return <FluentDropdown
                        options={[{ key: "", text: "View a related product" }, ...productLineData[0]?.RelatedProductLines.map(rp => ({ key: rp.Title, text: rp.Title }))]}
                        selectedKey=""
                        style={{ minWidth: 200, marginLeft: 10 }}
                        onChange={(_ev, option) => {
                            const product = option.key.toString();
                            if (product) {
                                navigate(`/product/${product}`);
                            }
                        }}
                    />
                }
            }
            ].filter(c => c)} farItems={[
                selectedFiles.length > 0 && {
                    key: "zip",
                    commandBarButtonAs: () => {
                        return <PrimaryButton
                            disabled={loadingContext.LoadingMessage !== undefined}
                            iconProps={{ iconName: "Download" }}
                            text={selectedFiles.length > 1 ? `Download Zip (${selectedFiles.length} documents)` : "Download"}
                            onClick={() => {
                                if (selectedFiles.length > 1) {
                                    zipFile();
                                }
                                else {
                                    downloadFile(selectedFiles[0]);
                                }
                            }}
                        />
                    }
                }
            ].filter(c => c)} />
            {!loadingContext.LoadingMessage?.hideBody &&
                <MarqueeSelection selection={selection} isEnabled={true}>
                    <DetailsList
                        items={[...items]}
                        groups={getDocSets(selectedRevision)}
                        columns={_columns}
                        onRenderDetailsHeader={_onRenderDetailsHeader}
                        groupProps={{
                            showEmptyGroups: false,
                            isAllGroupsCollapsed: true
                        }}
                        onRenderItemColumn={_onRenderColumn}
                        compact={false}
                        selectionMode={SelectionMode.multiple}
                        selection={selection}
                        selectionPreservedOnEmptyClick={true}
                        enterModalSelectionOnTouch={true}
                        setKey={"multiple"}
                        getKey={_getKey}
                    />
                </MarqueeSelection>}
        </div>
    </Stack>;

    function _onRenderDetailsHeader(props: IDetailsHeaderProps, _defaultRender?: IRenderFunction<IDetailsHeaderProps>) {
        return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={'Expand collapse groups'} />;
    }

    function _onRenderColumn(item: IDetailsListGroupedItem, index: number, column: IColumn) {
        const value = item && column && column.fieldName ? item[column.fieldName as keyof IDetailsListGroupedItem] || '' : '';
        return <div data-is-focusable={true}>{value}</div>;
    }
}